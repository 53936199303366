
import useDeskBooking from '@/composables/useDeskBooking';
import useNavigation from '@/composables/useNavigation';
import { displayEventTime } from '../utils/date.utils';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';
import { onMounted } from '@vue/runtime-core';

export default {
    name: 'MyReservations',
    setup() {
        const { employeeReservations } = useDeskBooking();
        const { gotoEditReservation, goHome } = useNavigation();
        const store = useMainStore();
        const { employeeModel } = storeToRefs(store);

        // TODO: deskBookingBetaV2 feature flag
        onMounted(() => {
            if (!store.hasdeskBookingBetaV2) {
                goHome();
            }
        });

        return {
            employeeModel,
            employeeReservations,
            displayEventTime,
            gotoEditReservation,
        };
    },
};
