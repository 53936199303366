import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b15cbafa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desk-reservations" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "metadata" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { title: "My reservations" }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.employeeReservations, (reservation) => {
          return (_openBlock(), _createElementBlock("div", {
            key: reservation.id,
            class: "reservation",
            onClick: ($event: any) => ($setup.gotoEditReservation(reservation.room_id, reservation.id))
          }, [
            _createElementVNode("h3", null, _toDisplayString(reservation.space.turf_meta.space_name), 1),
            _createElementVNode("div", _hoisted_3, [
              (reservation.space.turf_meta.building_name)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createTextVNode(_toDisplayString(reservation.space.turf_meta.building_name), 1),
                    (reservation.space.turf_meta.zone || reservation.space.turf_meta.floor)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, ", "))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (reservation.space.turf_meta.floor)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _createTextVNode(" floor " + _toDisplayString(reservation.space.turf_meta.floor), 1),
                    (reservation.space.turf_meta.zone)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, ", " + _toDisplayString(reservation.space.turf_meta.zone), 1))
                      : _createCommentVNode("", true)
                  ]))
                : (reservation.space.turf_meta.zone)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(reservation.space.turf_meta.zone), 1))
                  : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, _toDisplayString($setup.displayEventTime(reservation).fullDateString), 1)
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}